<template>
  <vs-row style="padding: 5%" justify="left">
    <h3>Shopping Bag</h3>
    <span class="material-symbols-outlined"> female </span>
    <vs-row style="padding: 4px 0" v-for="item in products"
      v-bind:key="item.productId + item.measurements + item.selectedColor" justify="left">
      <v-card elevation="1" style="padding: 4px" width="100%">
        <vs-row>
          <vs-col w="4">
            <router-link :to="{ name: 'ViewProduct', params: { productId: item.productId } }"
              style="text-decoration: none">
              <v-img :height="height" v-bind:src="item.productImage">
                <template v-slot:placeholder>
                  <v-skeleton-loader class="mx-auto" width="100%" height="100%" type="image"></v-skeleton-loader>
                </template>
              </v-img>
            </router-link>
          </vs-col>
          <vs-col style="padding-left: 5%" w="8">
            <vs-row justify="space-between">
              <vs-col w="9">
                <router-link :to="{ name: 'ViewProduct', params: { productId: item.productId } }"
                  style="text-decoration: none">
                  <div
                    style="font-size: 18px; font-weight: bold; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                    {{ item.productTitle }} </div>
                </router-link>
              </vs-col>
              <vs-col w="3" align="right">
                <v-btn style="margin-right: 4px;" x-small icon rounded @click.prevent="removeItem(item)">
                  <span style="font-size: 24px; color: black" class="material-symbols-outlined">
                    delete
                  </span>
                </v-btn>
              </vs-col>
            </vs-row>
            <div v-if="item.selectedColor" style="font-size: 14px"> Color: {{ item.selectedColor }}</div>
            <div v-if="item.measurements" style="font-size: 14px"> Size: {{ item.measurements }} </div>
            <div style="font-size: 18px; font-weight: bold">
              <i class="fa-solid fa-bangladeshi-taka-sign"></i> {{ item.productPrice }}
            </div>
            <div style="font-size: 14px; display: flex; align-items: center;">
              <v-btn small elevation="2" icon @click="decreaseQuantity(item)">
                <span v-if="item.quantity == 1" style="font-size: 16px;" class="material-symbols-outlined">
                  delete
                </span>
                <span v-if="item.quantity > 1" style="font-size: 16px;" class="material-symbols-outlined">
                  remove
                </span>
              </v-btn>
              <div
                style="text-align: center; margin: 8px; border-radius: 8px; width: 36px; border: 1px solid #ccc; padding: 4px;">
                {{ item.quantity }}</div>
              <v-btn small elevation="2" icon>
                <span style="font-size: 16px;" @click="increaseQuantity(item)" class="material-symbols-outlined">
                  add
                </span>
              </v-btn>
            </div>
            <p v-if="totalQuantity(item) > maxQuantity(item)" :style="{ color: COLORS.RED, fontWeight: 'bold' }" v-html="stockMessage(maxQuantity(item))"></p>
          </vs-col>
        </vs-row>
      </v-card>
    </vs-row>
  </vs-row>
</template>

<script>
import { COLORS } from '@/plugins/branding.js';
export default {
  name: 'Bag',
  props: ['products', 'color', 'height'],
  data() {
    return {
      COLORS
    };
  },
  methods: {
    stockMessage(quantity) {
      return quantity > 0 ? 'Only ' + quantity + ' left in stock! <br> Please lower quantity.' : `SOLD OUT! <br> Please remove from bag.`;
    },
    closeSnackbar(bool) {
      this.alert = bool;
    },
    removeItem(item) {
      this.$store.commit('removeItemFromBag', item);
    },
    maxQuantity(item) {
      return this.$store.state.products
        .find(product => product.productId === item.productId).productQuantity;
    },
    totalQuantity(item) {
      return this.$store.state.bag
        .filter(bagItem => bagItem.productId === item.productId)
        .reduce((sum, bagItem) => sum + bagItem.quantity, 0);
    },
    increaseQuantity(item) {
      if (this.totalQuantity(item) < this.maxQuantity(item)) {
        this.$store.commit('increaseItemQuantity', item);
      }
    },
    decreaseQuantity(item) {
      if (item.quantity > 1) {
        this.$store.commit('decreaseItemQuantity', item);
      } else {
        this.$store.commit('removeItemFromBag', item);
      }
    },
  },
};
</script>