import firebase from 'firebase/compat/app';

const firebaseConfig = {
  apiKey: "AIzaSyAg9U4xS-eDxJqfMUj7U0hsrof3zl8pKhE",
  authDomain: "bengalina-bd.firebaseapp.com",
  projectId: "bengalina-bd",
  storageBucket: "bengalina-bd.appspot.com",
  messagingSenderId: "171693709846",
  appId: "1:171693709846:web:554b7138d58e57d31c7337",
  measurementId: "G-FZG8LMEHBY"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
export default firebaseApp.firestore();


// const firebase = require('firebase/compat/app');
// require('firebase/compat/auth');
// require('firebase/compat/firestore');

// const firebaseConfig = {
//   apiKey: "AIzaSyAg9U4xS-eDxJqfMUj7U0hsrof3zl8pKhE",
//   authDomain: "bengalina-bd.firebaseapp.com",
//   projectId: "bengalina-bd",
//   storageBucket: "bengalina-bd.appspot.com",
//   messagingSenderId: "171693709846",
//   appId: "1:171693709846:web:554b7138d58e57d31c7337",
//   measurementId: "G-FZG8LMEHBY"
// };

// firebase.initializeApp(firebaseConfig);
// const db = firebase.firestore();
// module.exports = db;
