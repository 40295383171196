<template>
  <div>
    <v-navigation-drawer v-model="drawer" :style="{ backgroundColor: colors.PRIMARY, zIndex: 1050}"
      v-if="$vuetify.breakpoint.smAndDown" fixed temporary width="80%" min-height="100vh">
      <router-link :style="{ textDecoration: 'none', padding: '10%' }" :to="{ name: 'Home' }">
        <vs-row justify="center">
          <v-toolbar-title style="font-size: 24px; letter-spacing: 2px;">BENGALINA</v-toolbar-title>
        </vs-row>
      </router-link>
      <v-divider></v-divider>
      <router-link :style="{ textDecoration: 'none', color: colors.BLACK }" :to="{ name: 'Tops' }">
        <vs-row :style="{ paddingLeft: '14px' }">
          <p :style="{ paddingLeft: '10px', fontSize: '16px', fontWeight: '500' }">Tops</p>
        </vs-row>
      </router-link>
      <router-link :style="{ textDecoration: 'none', color: colors.BLACK }" :to="{ name: 'Bottoms' }">
        <vs-row :style="{ paddingLeft: '14px' }">
          <p :style="{ paddingLeft: '10px', fontSize: '16px', fontWeight: '500' }">Bottoms</p>
        </vs-row>
      </router-link>
      <router-link :style="{ textDecoration: 'none', color: colors.BLACK }" :to="{ name: 'Dresses' }">
        <vs-row :style="{ paddingLeft: '14px' }">
          <p :style="{ paddingLeft: '10px', fontSize: '16px', fontWeight: '500' }">Dresses</p>
        </vs-row>
      </router-link>
      <router-link :style="{ textDecoration: 'none', color: colors.BLACK }" :to="{ name: 'DressSets' }">
        <vs-row :style="{ paddingLeft: '14px' }">
          <p :style="{ paddingLeft: '10px', fontSize: '16px', fontWeight: '500' }">Dress sets</p>
        </vs-row>
      </router-link>
      <v-divider></v-divider>
      <router-link v-if="!user" :style="{ textDecoration: 'none', color: colors.BLACK }" :to="{ name: 'Login' }">
        <vs-row :style="{ paddingLeft: '14px' }">
          <p :style="{ paddingLeft: '10px', fontSize: '16px', fontWeight: '500' }">Sign In</p>
        </vs-row>
      </router-link>
      <router-link v-if="user" :style="{ textDecoration: 'none', color: colors.BLACK }" :to="{ name: 'Profile' }">
        <vs-row :style="{ paddingLeft: '14px' }">
          <p :style="{ paddingLeft: '10px', fontSize: '16px', fontWeight: '500' }">Profile</p>
        </vs-row>
      </router-link>
      <router-link :style="{ textDecoration: 'none', color: colors.BLACK }" :to="{ name: 'Support' }">
        <vs-row :style="{ paddingLeft: '14px' }">
          <p :style="{ paddingLeft: '10px', fontSize: '16px', fontWeight: '500' }">Support</p>
        </vs-row>
      </router-link>
    </v-navigation-drawer>
    <v-app-bar :style="{ backgroundColor: colors.PRIMARY }" square flat fixed>
      <div
        :style="{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', position: 'relative' }">
        <!-- left -->
        <div :style="{ display: 'flex', alignItems: 'center' }">
          <v-app-bar-nav-icon :color="colors.BLACK" v-if="$vuetify.breakpoint.smAndDown"
            @click="drawer = true"></v-app-bar-nav-icon>
          <router-link :style="{ textDecoration: 'none', paddingLeft: '12px' }" to="/">
            <v-toolbar-title style="font-size: 24px; letter-spacing: 2px;">BENGALINA</v-toolbar-title>
          </router-link>
          <vs-row style="padding-left: 12px;" justify="left" v-if="$vuetify.breakpoint.mdAndUp">
            <v-tabs optional>
              <router-link :style="{ backgroundColor: colors.PRIMARY, textDecoration: 'none' }" :to="{ name: 'Tops' }">
                <v-tab :style="{ color: colors.BLACK, height: '100%' }"> Tops </v-tab>
              </router-link>
              <router-link :style="{ backgroundColor: colors.PRIMARY, textDecoration: 'none' }"
                :to="{ name: 'Bottoms' }">
                <v-tab :style="{ color: colors.BLACK, height: '100%' }"> Bottoms </v-tab>
              </router-link>
              <router-link :style="{ backgroundColor: colors.PRIMARY, textDecoration: 'none' }"
                :to="{ name: 'Dresses' }">
                <v-tab :style="{ color: colors.BLACK, height: '100%' }"> Dresses </v-tab>
              </router-link>
              <router-link :style="{ backgroundColor: colors.PRIMARY, textDecoration: 'none' }"
                :to="{ name: 'DressSets' }">
                <v-tab :style="{ color: colors.BLACK, height: '100%' }"> Dress Sets </v-tab>
              </router-link>
            </v-tabs>
          </vs-row>
        </div>
        <!-- middle -->
        <!-- <div :style="{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }">
          <router-link :style="{ textDecoration: 'none' }" to="/">
            <img :width="$vuetify.breakpoint.smAndDown ? '100px' : '120px'" src="../assets/logo.png" />
          </router-link>
        </div> -->
        <!-- right -->
        <div :style="{ display: 'flex', alignItems: 'center' }">
          <!-- profile -->
          <v-speed-dial :right="right" :direction="direction" :open-on-hover="hover" :transition="transition">
            <template v-slot:activator>
              <div>
                <span class="material-symbols-outlined">
                  face_4
                </span>
              </div>
            </template>
            <router-link :style="{ textDecoration: 'none' }" :to="{ name: 'Login' }">
              <v-btn rounded v-if="!user" :color="colors.PRIMARY">
                Sign In
              </v-btn>
            </router-link>
            <router-link :style="{ textDecoration: 'none' }" :to="{ name: 'Profile' }">
              <v-btn rounded v-if="user" :color="colors.PRIMARY">
                Profile
              </v-btn>
            </router-link>
            <router-link :style="{ textDecoration: 'none' }" :to="{ name: 'AdminPanel' }">
              <v-btn rounded v-if="admin" :color="colors.PRIMARY">
                Admin Panel
              </v-btn>
            </router-link>
            <v-btn rounded @click="signOut()" v-if="user" :color="colors.PRIMARY">
              Sign Out
            </v-btn>
          </v-speed-dial>
          <!-- cart -->
          <v-badge v-if="bag.length" offset-x="10" offset-y="10" :content="addedProducts">
            <div @click.stop="bagToggle = !bagToggle">
              <span class="material-symbols-outlined">
                shopping_cart
              </span>
            </div>
          </v-badge>
          <div v-if="!bag.length" @click.stop="bagToggle = !bagToggle">
              <span class="material-symbols-outlined">
                shopping_cart
              </span>
            </div>
        </div>
      </div>
    </v-app-bar>
    <v-navigation-drawer :style="{ backgroundColor: colors.PRIMARY, zIndex: 1050}" v-model="bagToggle" right fixed temporary
      :width="$vuetify.breakpoint.smAndDown ? '80%' : '40%'" min-height="100vh">
      <div v-if="bagEmpty" class="d-flex flex-column align-items-center" :style="{ padding: '5% 10%' }">
        <div :style="{ fontSize: '32px', padding: '1%' }">Empty Bag!</div>
        <div :style="{ fontSize: '24px', padding: '1%' }">Let's get you shopping!</div>
        <router-link :style="{ textDecoration: 'none', padding: '5%' }" :to="{ name: 'AllProducts' }">
          <v-btn dark rounded :style="{ fontSize: '12px', }">
            Start Shopping
          </v-btn>
        </router-link>
      </div>
      <div v-if="!bagEmpty" style="padding-bottom: 84px;">
        <Bag :products="bag" :height="'150px'" />
        <div :style="{backgroundColor: colors.PRIMARY, display: 'flex', flexDirection: 'column', alignItems: 'flex-end', position: 'fixed', bottom: '0', padding: '14px', width: '100%' }">
          <div :style="{ padding: '2%' }">Total: <i class="fa-solid fa-bangladeshi-taka-sign"></i> {{ totalPrice }}
          </div>
          <router-link :style="{ textDecoration: 'none' }" :to="{ name: 'Checkout' }">
            <v-btn dark rounded :style="{ fontSize: '12px' }">
              Review and Checkout
            </v-btn>
          </router-link>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import Bag from './Bag.vue';
import { COLORS } from '@/plugins/branding.js';

export default {
  name: 'Navigation',
  components: { Bag },
  data() {
    return {
      drawer: false,
      bagToggle: false,
      direction: 'bottom',
      hover: true,
      right: true,
      colors: COLORS,
      addedProducts: null,
      transition: 'slide-x-reverse-transition',
    };
  },
  methods: {
    signOut() {
      firebase.auth().signOut();
      this.$router.push({ name: 'Home' });
      this.$store.commit('removeAdmin');
      window.location.reload();
    },
    removeItem() {
      this.$store.commit('removeItemFromBag', this.bag);
    },
    calculateAddedProducts() {
      this.addedProducts = this.bag.reduce((total, product) => total + product.quantity, 0);
    },
  },
  watch: {
    bag: {
      handler() {
        this.bagToggle = true;
        this.calculateAddedProducts();
      },
      deep: true
    }
  },
  mounted() {
    this.calculateAddedProducts();
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    admin() {
      return this.$store.state.profileAdmin;
    },
    bag() {
      return this.$store.state.bag;
    },
    allProducts() {
      return this.$store.state.products;
    },
    totalPrice() {
      let value = 0;
      this.bag.forEach((item) => {
        value += Number(item.productPrice) * item.quantity;
      });
      return value;
    },
    bagEmpty() {
      return this.bag.length === 0;
    },
  },
};
</script>