import Vue from 'vue';
import VueRouter from 'vue-router';
import firebase from 'firebase/compat/app';
import Home from '../views/Home.vue';
const AllProducts = () => import('@/views/AllProducts.vue');
const Tops = () => import('@/views/Tops.vue');
const BestSellers = () => import('@/views/BestSellers.vue');
const Dresses = () => import('@/views/Dresses.vue');
const DressSets = () => import('@/views/DressSets.vue');
const Bottoms = () => import('@/views/Bottoms.vue');
const Collection = () => import('@/views/Collection.vue');
const Login = () => import('@/views/Login.vue');
const Register = () => import('@/views/Register.vue');
const ForgotPassword = () => import('@/views/ForgotPassword.vue');
const Profile = () => import('@/views/Profile.vue');
const AdminPanel = () => import('@/views/AdminPanel.vue');
const ViewProduct = () => import('@/views/ViewProduct.vue');
const EditProduct = () => import('@/views/EditProduct.vue');
const Checkout = () => import('@/views/Checkout.vue');
const Support = () => import('@/views/Support.vue');

import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Home',
      requiresAuth: false,
    },
  },
  {
    path: '/tops',
    name: 'Tops',
    component: Tops,
    meta: {
      title: 'Tops',
      requiresAuth: false,
    },
  },
  {
    path: '/allProducts',
    name: 'AllProducts',
    component: AllProducts,
    meta: {
      title: 'All Products',
      requiresAuth: false,
    },
  },
  {
    path: '/bestSellers',
    name: 'BestSellers',
    component: BestSellers,
    meta: {
      title: 'Best Sellers',
      requiresAuth: false,
    },
  },
  {
    path: '/collection',
    name: 'Collection',
    component: Collection,
    meta: {
      title: 'Collection',
      requiresAuth: false,
    },
  },
  {
    path: '/dresses',
    name: 'Dresses',
    component: Dresses,
    meta: {
      title: 'Dresses',
      requiresAuth: false,
    },
  },
  {
    path: '/bottoms',
    name: 'Bottoms',
    component: Bottoms,
    meta: {
      title: 'Bottoms',
      requiresAuth: false,
    },
  },
  {
    path: '/dressSets',
    name: 'DressSets',
    component: DressSets,
    meta: {
      title: 'Dress Sets',
      requiresAuth: false,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: 'Login',
      requiresAuth: false,
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      title: 'Register',
      requiresAuth: false,
    },
  },
  {
    path: '/forgotPassword',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      title: 'Forgot Password',
      requiresAuth: false,
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      title: 'Profile',
      requiresAuth: true,
      requiresAdmin: false,
    },
  },
  {
    path: '/adminPanel',
    name: 'AdminPanel',
    component: AdminPanel,
    meta: {
      title: 'AdminPanel',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/viewProduct/productId=:productId',
    name: 'ViewProduct',
    component: ViewProduct,
    meta: {
      title: 'Product',
      requiresAuth: false,
    },
  },
  {
    path: '/editProduct/productId=:productId',
    name: 'EditProduct',
    component: EditProduct,
    meta: {
      title: 'Edit Product',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: Checkout,
    meta: {
      title: 'Checkout',
      requiresAuth: false,
    },
  },
  {
    path: '/support',
    name: 'Support',
    component: Support,
    meta: {
      title: 'Support',
      requiresAuth: false,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} | Bengalina`;
  next();
});

router.beforeEach(async (to, from, next) => {
  const user = firebase.auth().currentUser;
  if (to.matched.some((res) => res.meta.requiresAuth)) {
    if (user) {
      if (to.matched.some((res) => res.meta.requiresAdmin)) {
        if (store.state.profileAdmin) {
          return next();
        }
        return next({ name: 'Home' });
      }
      return next();
    }
    return next({ name: 'Home' });
  }
  return next();
});

export default router;
